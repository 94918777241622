@use "variables";
@import "mixins";

#hero {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;

  .backgroundContainer {
    z-index: -100;
    background-image: url("../assets/svg/landingBackground.svg");
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    background-size: contain;
  }
  .heroContainer {
    margin-top: 100px;
    align-self: center;
    justify-content: center;
    display: flex;
    flex-direction: row;

    .profileImage {
      margin-top: 50px;
    }

    .contentSection {
      // width: 800px;
      // margin-left: 30px;
      user-select: none;

      .title {
        color: variables.$light;
        font-size: 100px;
        font-weight: 700;
        line-height: 120px;
        letter-spacing: -7px;
        cursor: pointer;
      }

      .greetings {
        color: variables.$accent;
        font-size: 25px;
        font-weight: 300;
        margin-left: 4px;
        // font-weight: 200;
      }

      .introText {
        color: variables.$light005;
        font-size: 20px;
        font-family: Manrope;
        font-weight: 300;
        max-width: 650px;
      }

      .buttonWrapper {
        margin-top: 100px;
        display: flex;
        .contactButton {
          margin-left: 20px;
        }
      }
    }
  }
}

.profileImage {
  height: 100px;
  height: 100px;
  border-radius: 50px;
}

.svgBackground {
  position: absolute;
  width: 100vw;
}

.svgBackground img {
  width: 100vw;
}

.alternateContactButton {
  background-color: #89fdea22;
  border: none;
  margin-left: 20px;
  display: none;
}
@media (max-width: variables.$tabWidth) {
  #hero {
    .heroContainer {
      flex-direction: column;
      // .alternativeProfileSection {
      //   margin-top: -100px;
      // }
      .contentSection {
        // margin-top: -90px;
        .title {
          font-size: 80px;
          line-height: 70px;
          margin-top: 20px;
          margin-bottom: 40px;
        }
        .introText {
          // font-size: 16px;
        }
        .buttonWrapper {
          // margin-top: 0px;
          // background-color: rgba(43, 226, 220, 0.206);
        }
      }

      .alternateContactButton {
        display: block;
      }
      .contactButton {
        display: none;
      }
    }

    .enclosingCircle {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  #hero {
    .backgroundContainer {
      z-index: -100;
      background-image: url("../assets/svg/landingPageSmallBG.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: 900px) and (min-width: 600px) {
  #hero {
    .backgroundContainer {
      background-size: cover;
    }
  }
}

@media (max-width: variables.$themeWidth) {
  .profileImage {
    display: none;
  }
}
